
import { Component, Prop, Watch } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

// TODO: two ways data binding
@Component
export default class NumberInput extends VueStrong {
  @Prop({ required: true, default: 0 }) quantity!: number
  @Prop({ required: true, default: Infinity }) max!: number
  @Prop({ required: false, default: -Infinity }) min?: number
  @Prop({ required: true, default: '' }) label!: string
  @Prop({ required: false, default: null }) index!: number
  @Prop({ required: false, default: false }) disabled!: boolean
  @Prop() required?: boolean
  error = false

  changeQuantity(value: any) {
    if (this.min <= value && value <= this.max) this.$emit('change', value, this.index)
    else this.error = true
  }
}
