
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import SettingsCard from '@/components/SettingsCard.vue'
import { objectives } from '@/helpers/data'

@Component({ components: { SettingsCard } })
export default class CampaignObjective extends VueStrong {
  @Prop({ required: true }) value: any
  @Prop({ default: false }) error: boolean

  get availableObjectives() {
    return objectives.map(o => ({ label: o.label, value: o.value, description: o.description }))
  }

  onObjectiveSelect(objective: any) {
    this.$emit('input', objective)
  }

  objectiveCardStyles(value: string) {
    if (this.value === value) return 'objective active'
    return 'objective'
  }
}
