
import { Component, Prop, PropSync } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import { bidStrategies } from '@/helpers/data'
import NumberInput from '@/components/NumberInput.vue'

@Component({
  components: {
    NumberInput
  }
})
export default class CampaignBudget extends VueStrong {
  @PropSync('value', { type: Object }) editableValue!: Partial<yakkyo.IFacebookCampaign>

  get bidStrategies() {
    return bidStrategies
  }

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount : null
  }

  get currency() {
    if (!this.selectedAdAccount) return 'USD'
    return this.selectedAdAccount.currency || 'USD'
  }

  updateBudget(budget: number) {
    this.editableValue.budget = budget
    console.log('emit?')
    this.$emit('update')
  }
}
